import { postRequest } from "../../connection/network";

const pollsApi = {
  create: "qr",
};

export const createQrService = async ({ domain }) => {
  try {
    let {
      data: { data, message },
    } = await postRequest({ body: { domain }, endPoint: pollsApi.create });

    return {
      data,
      message,
    };
  } catch (error) {
    const sError = error?.response?.data?.error?.message;
    return { error: sError };
  }
};
