import TextField from "@mui/material/TextField";
import { useState } from "react";
import Button from "@mui/material/Button";
import { Box, CircularProgress, Container } from "@mui/material";
import { createQrService } from "./services/qr/qr";

function App() {
  const [domain, setDomain] = useState();
  const [qr, setQr] = useState();
  const [loading, setLoading] = useState();

  const generateQr = async () => {
    try {
      setLoading(true);
      const data = await createQrService({ domain });
      setQr(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: 1,
        py: 8,
      }}
    >
      <TextField
        label="Domain"
        value={domain}
        onChange={(e) => setDomain(e?.target?.value)}
        sx={{ mb: 2 }}
      />
      <Button
        onClick={generateQr}
        disabled={!domain || loading}
        variant="contained"
      >
        Generate
      </Button>

      <Box sx={{ mt: 4 }}>
        {loading ? (
          <Box sx={{ py: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <img src={qr?.data} alt="" />
        )}
      </Box>
    </Container>
  );
}

export default App;
